// import RegularFont from "./font/Poppins-Regular.ttf";
// import BoldFontUrl from "./font/Poppins-SemiBold.ttf";
import {
  accuracyFormat,
  DateisValid,
  parseRange,
} from "../../../utils/components/accuracyandlcandrangesshow";
import { printdatasheetConfigData } from "./datasheetConstant";

export let printdatasheetSection = {
  firstpagelikeJRPM: false,
  lastpagelikeJRPM: false,
  dateofPerformancelikeJrpm: false,
  disciplineAndReadinTableStartNewpage: false,
  jrpmtable: false,
  readingTableSerialNoColumnShow: true,
};

export const headerConfig = {
  headerCALIBRATIONDATASHEET: false,
  headerCALIBRATIONDATASHEETgap: 10,
  headerCALIBRATIONDATASHEETwithoutgap: 7,
  lettterImgHeight: 0,
  headerTableHeight: 85,
  headerandbodygap: 10,
  tableRowfontSizes: 9,
  cellPadding: { top: 4, left: 5, bottom: 4, right: 5 },
};

export const calculateHeader = {
  headerCALIBRATIONDATASHEETExpostion: headerConfig.headerCALIBRATIONDATASHEET
    ? headerConfig.lettterImgHeight + headerConfig.headerCALIBRATIONDATASHEETgap
    : headerConfig.lettterImgHeight +
      headerConfig.headerCALIBRATIONDATASHEETwithoutgap,
  headerCALIBRATIONDATASHEETExpostionafter:
    headerConfig.lettterImgHeight +
    headerConfig.headerCALIBRATIONDATASHEETgap +
    10,
};

export const totalHeaderHeightWithCalibrationLableorNot = {
  total: headerConfig.headerCALIBRATIONDATASHEET
    ? headerConfig.lettterImgHeight + headerConfig.headerTableHeight + 20
    : headerConfig.lettterImgHeight + headerConfig.headerTableHeight + 7,
};

export const footerConfig = {
  footerCALIBRATIONDATASHEET: false,
  footerimgHeight: 0,
  footertableHeight: 50,
  footerandbodygap: 57,
  enddatasheetandimggap: 5,
  footerCompanyNameFirstRow: "UNITED CALIBRATIONS PVT. LTD.",
};
let pageHeight = 841;
export const datasheetBorderLayout = {
  x: 15,
  y: totalHeaderHeightWithCalibrationLableorNot.total - 5,
  width: 565,
  height:
    pageHeight -
    footerConfig.footerimgHeight -
    footerConfig.footertableHeight -
    footerConfig.footerandbodygap -
    totalHeaderHeightWithCalibrationLableorNot.total +
    25,
  // height: 570
};

export const tableConfiguration = {
  margins: { top: 0, right: 25, bottom: 0, left: 25 },
};

export const tableConfigurationheaderandfooter = {
  margins: { top: 0, right: 15, bottom: 0, left: 15 },
};

export const fontFamilyConfig = {
  useFont: "Aptos",
  bold: "bold",
  normal: "normal",
};

export const waterMarkImgConfig = {
  logoWidth: 250,
  logoHeight: 200,
};

export const environmentTableConfig = {
  spacebetweenHeadingandTable: 5,
  innerBorder: 0,
  cellPadding: { top: 3, left: 5, bottom: 3, right: 5 },
};

export const loadAndAddFont = async (
  pdfDoc,
  fontUrl,
  fontNameTTF,
  fontName,
  fontStyle
) => {
  const arrayBufferToBase64 = (buffer) => {
    let binary = "";
    const bytes = new Uint8Array(buffer);
    const chunkSize = 8192; // Process in chunks for large buffers
    for (let i = 0; i < bytes.length; i += chunkSize) {
      binary += String.fromCharCode(...bytes.subarray(i, i + chunkSize));
    }
    return btoa(binary);
  };

  const fontResponse = await fetch(fontUrl);
  const fontBuffer = await fontResponse.arrayBuffer();
  const fontBase64 = arrayBufferToBase64(fontBuffer);

  pdfDoc.addFileToVFS(`${fontNameTTF}.ttf`, fontBase64);
  pdfDoc.addFont(`${fontNameTTF}.ttf`, fontName, fontStyle);
};

export const customFontFamily = {
  bold: "bold",
  normal: "normal",
  boldFont: "AptosBold",
  normalFont: "AptosNormal",
};

export const digitallySignConfig = {
  digitallySignONorOFF: false,
  signatureTextContnet: [
    `REVA PHOENIX LABS AND CONSULTANTS`,
    `PRIVATE LIMITED`,
    `Date:  ${new Date()
      .toLocaleString("en-GB", { timeZone: "Asia/Kolkata" })
      .replace(",", "")} +05:30`,
    `Reason: Calibration Certificate`,
    `Location: Chennai`,
  ],
};

// Define a function to generate the textList array
export const certificateFirstSectionHeading = (
  disciplineName,
  datasheetDetails
) => {
  return [
    { text: disciplineName || "", fontSize: 17 },
    { text: "Calibration Certificate", fontSize: 17 },
    { text: "For", fontSize: 17 },
    {
      text: datasheetDetails?.requestedname
        ? datasheetDetails?.requestedname
        : datasheetDetails?.instrumentName || "",
      fontSize: 17,
    },
  ];
};

// Exporting MasterEQPDetails
export const MasterEQPDetails = (standards) => {
  const fieldKeys = [
    { key: "Sl.No.", field: "slNo" },
    { key: "Name of Standard", field: "standardName" },
    { key: "Id/Sr. No.", field: "stId" },
    { key: "Range", field: "masterrange" },
    { key: "Accuracy", field: "masteraccuracy" },
    { key: "Least Count", field: "masterleastcount" },
    { key: "Certificate No", field: "certificateNumber" },
    { key: "Calibration Date", field: "calDate" },
    { key: "Due Date", field: "validUpto" },
  ];

  // Determine filteredKeys by checking for valid data across all standards
  const filteredKeys = fieldKeys
    .filter(({ field }) => {
      if (field === "slNo") return true; // Always include "Sl.No."
      return standards.some((item) => item[field]); // Check if at least one standard has valid data for the field
    })
    .map(({ key }) => key);

  // Generate the values based on filteredKeys
  const values = standards.map((item, index) => {
    return filteredKeys.map((key) => {
        switch (key) {
          case "Sl.No.":
            return index + 1;

          case "Name of Standard":
            return item.standardName || "";

          case "Id/Sr. No.":
            return item.stId && item.serialNo
              ? `${item.stId} / ${item.serialNo}`
              : item.stId || item.serialNo || "";

          case "Range":
            return item.masterrange ? parseRange(item.masterrange) : "";

          case "Accuracy":
            return item.masteraccuracy ? accuracyFormat(item.masteraccuracy) : "";

          case "Least Count":
            return item.masterleastcount
              ? parseRange(item.masterleastcount)
              : "";

          case "Certificate No":
            return item.certificateNumber || "";

          case "Calibration Date":
            return item.calDate ? DateisValid(item.calDate) : "";

          case "Due Date":
            return item.validUpto ? DateisValid(item.validUpto) : "";

          default:
            return "";
        }
      });
  });

  return {
    heading: "DETAILS OF MASTER EQP USED FOR CALIBRATION",
    headingFontSize: 9,
    headingBottomSpace: 0,
    headingTopSpace: 0,
    headingAlign: "center",
    spacebetweenHeadingandTable: 5,
    cellPadding: { top: 4, left: 5, bottom: 4, right: 5 },
    tableTextfontSize: 9,
    tableValueAlign: "center",
    keys: filteredKeys, // Only valid keys
    values,
  };
};

export const UUCDetails = (datasheetDetails) => {
  const getExtraColumn = (key) => {
    const value = datasheetDetails?.extraColumns?.find(([k, v]) => k === key);
    // Return the column only if the value is not an empty string or null
    return value && value[1] !== "" && value[1] !== null
      ? { key, value: value[1] }
      : null;
  };

  const standardFields = [
    {
      key: "Name of Instrument",
      value:
        datasheetDetails?.requestedname || datasheetDetails?.instrumentName,
    },
    { key: "Make", value: datasheetDetails?.make },
    { key: "Identification No", value: datasheetDetails?.DUCID },
    { key: "Model", value: datasheetDetails?.model },
    { key: "Serial No", value: datasheetDetails?.serialNo },
    { key: "Range", value: parseRange(datasheetDetails?.ranges) },
    { key: "Accuracy", value: accuracyFormat(datasheetDetails?.accuracy) },
    {
      key: "Resolution/Least Count",
      value: parseRange(datasheetDetails?.lc),
    },
    { key: "Location", value: datasheetDetails?.locationOfInstrument },
    {
      key: "Calibration Location",
      value: datasheetDetails?.location,
    },
    { key: "Condition of Instrument", value: datasheetDetails?.conOfDuc },
    {
      key: "Calibration Procedure",
      value: datasheetDetails?.calProcRefNo
        ? datasheetDetails?.calProcRefNo
        : datasheetDetails?.calibrationProcedureNo,
    },
    // { key: "Operating Range", value: parseRange(datasheetDetails?.operatingRange) },
    {
      key: "Standard Reference",
      value: datasheetDetails?.isaStandard
        ? datasheetDetails?.isaStandard
        : datasheetDetails?.referenceStandards,
    },
  ];

  // Extra fields
  const extraFields = ["Dimension Multi Resolution"];

  // Add extra fields only if they have a value
  const extraPanelFields = extraFields
    .map(getExtraColumn)
    .filter((item) => item !== null);
  // Filter standard fields to include only those with values
  const filteredStandardFields = standardFields.filter((field) => field.value);
  // Combine filtered standard fields with extra fields (no special ordering)
  const allFields = [...filteredStandardFields, ...extraPanelFields];
  // Create rows from the fields, pairing key-value pairs
  const rows = [];
  for (let i = 0; i < allFields.length; i += 2) {
    rows.push([allFields[i], allFields[i + 1] || null]); // Add extra field as null if there's no pair
  }

  return {
    heading: "DETAILS OF UNIT UNDER CALIBRATION (UUC)",
    headingFontSize: 9,
    headingBottomSpace: 0,
    headingTopSpace: 0,
    headingAlign: "center",
    spacebetweenHeadingandTable: 5,
    innerBorder: 0,
    cellPadding: { top: 2, left: 5, bottom: 2, right: 5 },
    tableTextfontSize: 9,
    middleColumn: true,
    rows,
  };
};

export const datasheetfirstBox = (details) => {
  let column = 6;
  let firstRow = [
    { key: "SRF Number", value: details?.certificateDetails?.serviceReqNumber },
    { key: "SRF Date	", value: DateisValid(details?.srfDetails?.entryDate) },
    {
      key: "Calibration Frequency",
      value:
        details?.datasheetDetails?.calibrationFrequency,
    },
  ];
  let secondRow = [];
  return {
    firstRow,
    secondRow,
    column,
    cellPadding: { top: 3, left: 5, bottom: 3, right: 5 },
  };
};

export const NameAndAddressDetails = (datasheetDetails) => {
  return {
    leftPanel: {
      keys: ["Customer Name & Address"],
      values: [
        `${datasheetDetails?.companyName || ""}\n${
          datasheetDetails?.newAddressArray?.address || ""
        }`,
      ],
    },
    rightPanel: {
      keys: ["Date of Receipt", "Date Calibration", "Due Date", "Date Issue"],
      values: [
        DateisValid(datasheetDetails?.receiptDate),
        DateisValid(datasheetDetails?.calibrationDate),
        datasheetDetails?.calFrequency == "Not Required" ? "-" : datasheetDetails?.calFrequency == "Customer Defined" ? "Customer Defined" : DateisValid(datasheetDetails?.nextDueDate),
        datasheetDetails?.dateOfIssue
          ? DateisValid(datasheetDetails?.dateOfIssue)
          : DateisValid(datasheetDetails?.approvedDate),
      ],
    },
    cellPadding: { top: 3, left: 5, bottom: 3, right: 5 },
    tableTextfontSize: 9,
  };
};

export const performanceClaibrationDetails = (datasheetDetails) => ({
  leftPanel: {
    keys: ["Date of Performance of Calibration"],
    values: [DateisValid(datasheetDetails?.calibrationDate)],
  },
  rightPanel: {
    keys: ["Date of Issue", "Recommended Due Date"],
    values: [
      datasheetDetails?.dateOfIssue
        ? DateisValid(datasheetDetails?.dateOfIssue)
        : DateisValid(datasheetDetails?.approvedDate),
      DateisValid(datasheetDetails?.nextDueDate),
    ],
  },
});

export const dynamicHeader = (datasheetDetailsConfig) => {
  return {
    leftPanel: {
      keys: ["ULR Number"],
      values: [datasheetDetailsConfig?.ULRNo],
    },
    rightPanel: {
      keys: ["Certificate Number"],
      values: [datasheetDetailsConfig?.certificateNumber || ""],
    },
  };
};


export const DatasheetfooterDetails = () =>{
  let LabelRow = ["CALIBRATED BY", "Approved By"];
  let RoleRow = [
    printdatasheetConfigData.footerSignSettingConfig?.calibratedBy,
    printdatasheetConfigData.footerSignSettingConfig?.calibratedBy,
  ];
  let electronicallyRow = [
    "Electronically signed by",
    "Electronically signed by",
  ];
  let DateRow = [
    DateisValid(
      printdatasheetConfigData.datasheetContextData?.datasheetDetails
        ?.calibrationDate
    ),
    DateisValid(
      printdatasheetConfigData.datasheetContextData?.datasheetDetails
        ?.calibrationDate
    ),
  ];
  let signimgRow = [
    printdatasheetConfigData.datasheetContextData?.calibratedByImgSign,
    printdatasheetConfigData.datasheetContextData?.datasheetApprovedByImgSign,
  ];
  return {
    LabelRow,
    RoleRow,
    electronicallyRow,
    DateRow,
    signimgRow
  }
}